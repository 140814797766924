import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import i18n from 'i18next';

// components
import Header from './Layout/Header';
import { Button, Form, Layout, Select } from 'antd';

// utils
import { useSession } from '../Providers/SessionProviders';
import {
  API_URL,
  APP_ENV,
  DEFAULT_ENGLISH_ID,
  DEFAULT_ENVIRONMENT,
} from '../constant';
import { colorPalette } from '../theme';
import { translate } from '../i18n/i18n';

const { Content } = Layout;

const StyledLayout = styled(Layout)`
  height: 100vh;
  background-color: ${colorPalette.grey};
`;

const LanguageWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25%;

  .ant-form-item-explain-error {
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    width: 40%;
  }

  @media (max-width: 420px) {
    width: 60%;
  }
`;

const Container = styled.div`
  // padding: 20px;
  width: 80%;
  margin: 0 auto;
`;

const TermsContainer = styled.div`
  height: calc(100vh - 180px);
  display: flex;
  flex-direction: column;
`;

const TextWrapper = styled.div`
  flex: 1;
  overflow-y: scroll;

  p {
    font-size: 16px;
  }
`;

const ButtonContainer = styled.div`
  position: fixed;
  bottom: 12px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const t = translate(['common']);

const SelectLanguage = () => {
  const { currentUser, setCurrentUser, setShowLanguageDropdown } = useSession();
  const [form] = Form.useForm();
  const termsContainerRef = useRef(null);
  const [languages, setLanguages] = useState([]);
  const [showTerms, setShowTerms] = useState(true);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);

  useEffect(() => {
    setShowLanguageDropdown(false);
    setIsScrolledToBottom(
      termsContainerRef.current?.scrollHeight ===
        termsContainerRef.current?.clientHeight,
    );
    return () => {
      setShowLanguageDropdown(true);
    };
  }, []);

  useEffect(() => {
    if (currentUser?.company?.languages?.length > 0) {
      setLanguages(
        currentUser?.company?.languages.map((ele) => ({
          ...ele,
          label: ele.text,
          value: ele.id,
        })),
      );
    }
  }, [currentUser]);

  const handleScroll = () => {
    const container = termsContainerRef.current;
    if (container) {
      const isBottom =
        container.scrollHeight - container.scrollTop === container.clientHeight;
      setIsScrolledToBottom(isBottom);
    }
  };

  const onSubmit = async ({ language }) => {
    const selectedLanguage = languages.find((ele) => ele.id === language);
    i18n.changeLanguage(selectedLanguage?.code);
    setCurrentUser({ ...currentUser, language });
    if (APP_ENV !== DEFAULT_ENVIRONMENT.DEMO) {
      await axios.patch(`${API_URL}/accounts/language/`, { language });
    }
  };

  return (
    <StyledLayout id="layout">
      <Header showLanguageDropdown={false} />
      <Content>
        {showTerms ? (
          <Container>
            <h2>{t('selectLanguage.terms')}</h2>
            <TermsContainer>
              <TextWrapper ref={termsContainerRef} onScroll={handleScroll}>
                <p>
                  I hereby undertake to treat as confidential any and all
                  information that I receive during my induction program
                  (Centurion Journey) about Mahindra Insurance Brokers Limited
                  and its employee policies & processes, business policies &
                  processes and employee information and I further agree to use
                  this information solely for the purpose of learning, to not
                  disclose it to any third party and to not make it publicly
                  available or accessible in any way whatsoever.
                </p>
                <p>
                  I understand that this non-disclosure agreement concerns any
                  and all information in any form that comes to my knowledge
                  during my participation in the induction program. I understand
                  that I shall be bound by this non-disclosure agreement as of
                  the date of my consent hereto and that I shall be bound by
                  this non-disclosure agreement even after my participation in
                  the induction program has been completed and/ or ended.
                </p>
                <p>
                  I agree that any disputes that may arise from my breach or
                  violation of this non-disclosure agreement shall be subject to
                  the exclusive jurisdiction of the courts of Mumbai.
                </p>
              </TextWrapper>
            </TermsContainer>
            <ButtonWrapper>
              <ButtonContainer>
                <Button
                  type="primary"
                  disabled={!isScrolledToBottom}
                  onClick={() => {
                    // setShowTerms(false);
                    onSubmit({ language: DEFAULT_ENGLISH_ID });
                  }}>
                  {t('agreeTermsCondition')}
                </Button>
              </ButtonContainer>
            </ButtonWrapper>
          </Container>
        ) : (
          <LanguageWrapper>
            <Form layout="vertical" form={form} onFinish={onSubmit}>
              <Form.Item
                name="language"
                rules={[
                  { required: true, message: t('selectLanguage.required') },
                ]}
                label={t('selectLanguage.label')}>
                <Select
                  placeholder={t('selectLanguage.placeholder')}
                  options={languages}
                />
              </Form.Item>
              <Form.Item>
                <Button block htmlType="submit" type="primary">
                  {t('save')}
                </Button>
              </Form.Item>
            </Form>
          </LanguageWrapper>
        )}
      </Content>
    </StyledLayout>
  );
};

export default SelectLanguage;
