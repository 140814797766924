import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Dropdown, Layout } from 'antd';
import axios from 'axios';
import i18n from 'i18next';

import NaschLogo from '../../assets/nasch-logo.svg';
import {
  DEFAULT_LOGO,
  DEFAULT_LOGO_HEIGHT,
  BACKY_ICON,
  API_URL,
  DEFAULT_ENVIRONMENT,
  APP_ENV,
  MAHINDRA_SLUG,
} from '../../constant';
import { DownOutlined } from '@ant-design/icons';
import { useSession } from '../../Providers/SessionProviders';

const { Header: AntHeader } = Layout;

const StyledHeader = styled(AntHeader)`
  &.ant-layout-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 1;
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid #e2e3e;
    padding-inline: 30px;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
`;

const StyledImage = styled.img`
  // width: 122.5px;
  height: ${DEFAULT_LOGO_HEIGHT}px;
`;

const StyledLogoSample = styled.div`
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgb(192 172 172 / 30%);
`;

const BackyLogo = styled.img`
  margin-left: 20px;
  height: 65px;
`;

const Header = () => {
  const { currentUser, loginLoading, showLanguageDropdown, setCurrentUser } =
    useSession();
  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    if (currentUser?.company?.languages?.length > 0) {
      setLanguages(
        currentUser?.company?.languages.map((ele) => ({
          ...ele,
          key: ele.code,
          label: ele.text,
          onClick: () => onLanguageChange(ele.id, ele.code),
        })),
      );
    }
  }, [currentUser]);

  const getLanguageLabel = useCallback(() => {
    const selectedLanguage = languages.find(
      (ele) => currentUser?.language === ele.id,
    );
    return selectedLanguage?.label || 'English';
  }, [languages, currentUser]);

  const onLanguageChange = async (language, code) => {
    if (i18n.language === code) return;
    i18n.changeLanguage(code);
    setCurrentUser({ ...currentUser, language });
    if (APP_ENV !== DEFAULT_ENVIRONMENT.DEMO) {
      await axios.patch(`${API_URL}/accounts/language/`, { language });
    }
  };

  return (
    <StyledHeader>
      <LogoWrapper>
        {loginLoading ? (
          <StyledLogoSample />
        ) : (
          <>
            <StyledImage src={DEFAULT_LOGO || NaschLogo} alt="logo" />
            {BACKY_ICON && <BackyLogo src={BACKY_ICON} alt="backy" />}
          </>
        )}
      </LogoWrapper>
      {showLanguageDropdown &&
        languages?.length > 0 &&
        currentUser?.company?.slug != MAHINDRA_SLUG && (
          <Dropdown menu={{ items: languages }} arrow={true}>
            <Button ghost type="primary">
              {getLanguageLabel()} <DownOutlined />
            </Button>
          </Dropdown>
        )}
    </StyledHeader>
  );
};

export default Header;
